import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isToday from 'dayjs/plugin/isToday';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import 'dayjs/locale/en-gb';
import 'dayjs/locale/nl';

dayjs.extend(isoWeek);
dayjs.extend(isToday);
dayjs.extend(duration);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);

export default {
    install: (app, { locale }) => {
        if (locale === 'nl') {
            dayjs.locale('nl');
        }

        if (locale === 'en') {
            dayjs.locale('en-gb');
        }

        app.provide('dayjs', dayjs);
    },
};
