export default {
    global: {
        outer: 'w-full',
        inner: 'relative',
        label: 'block leading-tight font-medium',
        input: 'block w-full px-4 py-2 placeholder-black bg-white border rounded-md focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-green focus:border-green sm:text-sm disabled:bg-gray-100 min-h-10',
        help: 'text-xs text-gray-500',
        messages: 'list-none p-0 mt-1 mb-0',
        message: 'text-red-600 mb-1 text-xs flex items-center gap-2 ml-2',
        prefixIcon: 'absolute top-1/2 -translate-y-1/2 w-6 left-3 pointer-events-none',
        suffixIcon: 'absolute top-1/2 -translate-y-1/2 w-6 right-3 pointer-events-none',
    },
    search: {
        input: 'pl-10',
    },
    button: {
        input: '$reset relative w-full px-4 py-2 text-white transition-colors duration-300 border border-transparent rounded-md bg-green-dark hover:bg-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green min-h-10 flex-center focus:text-white',
        wrapper: 'mt-4',
    },
    checkbox: {
        input: '$reset block w-4 h-4 mr-4  accent-green focus:outline-none disabled:bg-gray-100',
        wrapper: 'flex items-center',
        label: '$reset block leading-tight mb-0 select-none ',
        decorator: 'hidden',
    },
    select: {
        outer: 'min-w-20',
        input: 'appearance-none pr-10',
        selectIcon: 'absolute top-1/2 -translate-y-1/2 w-6 right-3 pointer-events-none',
    },
    textarea: {
        input: 'h-43',
    },
    radio: {
        legend: 'block mb-2 leading-tight font-medium',
        wrapper: 'flex items-center gap-3',
        label: '$reset cursor-pointer',
        input: '$reset focus:ring-green h-4 w-4 text-green border-green appearance-none rounded-full bg-white border-gray-darker border checked:bg-green p-[2px] bg-clip-content',
        inner: 'h-4',
        option: '',
        options: 'flex flex-col gap-2',
    },
};
